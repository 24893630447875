import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";
import Dashboard from "containers/Dashboard";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MoshipContainer from "components/Container";
import { usePushPath } from "moship";
import Locations from "./Locations";
import Rates from "./Rates";

interface ShippingByCityProps {}

const ShippingByCity: React.FC<ShippingByCityProps> = () => {
  const { t } = useTranslation();
  const pushPath = usePushPath();
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Dashboard>
      <MoshipContainer
        onGoBack={() => {
          pushPath("/carrier-calculated-shipping/");
        }}
        title={t("Shipping by city")}
      >
        <Tabs
          onChange={(index) => {
            setActiveTab(index);
          }}
        >
          <TabList>
            <Tab>{t("destination-location", "Location")}</Tab>
            <Tab>{t("Rates")}</Tab>
          </TabList>
        </Tabs>
        <Alert status="info" marginY={"1rem"}>
          <AlertIcon />
          <AlertTitle>
            {t(
              "we-upload-your-rates-for-you",
              "Send us your rates in a CSV file and we will upload them for you"
            )}
          </AlertTitle>
        </Alert>
        <Box marginY={"1rem"}>
          {activeTab === 0 && <Locations />}
          {activeTab === 1 && <Rates />}
        </Box>
      </MoshipContainer>
    </Dashboard>
  );
};

export default ShippingByCity;
